body.c1 {
  --sitecolor: #05612e;
  --section-background: transparent;
  --section-color: #000;
  --h1-color: #f5d10f;
  --h2-color: #05612e;
  --h3-color: #05a610;
  --mood-index: #05a610;
  --mood-index-active: #05612e;
  --slash-color: #05a610;
  --link-color: #000;
  --link-color-hover: #05a610;
  --footer-border-color: #f5d10f;
  --weird-transparent-background: rgba(1, 24, 11, 0.8);
}
body.c1 h1 {
  --link-color: #f5d10f;
  --link-color-hover: #05a610;
}
body.c1 h2 {
  --link-color: #05612e;
  --link-color-hover: #000;
}
body.c1 h3 {
  --link-color: #05a610;
  --link-color-hover: #000;
}
body.c1 .section--green,
body.c1 .ns-unitFilter__item,
body.c1 .ns-unitFilter__toggle {
  --section-background: #05612e;
  --section-color: #fff;
  --h2-color: #fff;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
  --slash-color: #f5d10f;
}
body.c1 .section--green-light {
  --section-background: #05a610;
  --slash-color: #f5d10f;
  --h3-color: #fff;
}
body.c1 .section--five {
  --h3-color: #f5d10f;
}
body.c1 .section--footer {
  --section-background: #05612e;
  --section-color: #fff;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
}
body.c1 .section--navigation .navbar,
body.c1 .mobile-navigation {
  --link-color: #fff;
  --slash-color: #f5d10f;
}
body.c1 .section--navigation .navbar .sub2,
body.c1 .mobile-navigation .sub2 {
  --link-color: #000;
}
body.c2 {
  --sitecolor: #c21f1e;
  --section-background: transparent;
  --section-color: #000;
  --h1-color: #f5d10f;
  --h2-color: #4b4b4d;
  --h3-color: #c21f1e;
  --mood-index: #4b4b4d;
  --mood-index-active: #c21f1e;
  --slash-color: #c21f1e;
  --link-color: #000;
  --link-color-hover: #c21f1e;
  --footer-border-color: #c21f1e;
  --weird-transparent-background: #c21f1e;
}
body.c2 h1 {
  --link-color: #f5d10f;
  --link-color-hover: #000;
}
body.c2 h2 {
  --link-color: #4b4b4d;
  --link-color-hover: #000;
}
body.c2 h3 {
  --link-color: #c21f1e;
  --link-color-hover: #000;
}
body.c2 .section--green {
  --section-background: #4b4b4d;
  --section-color: #fff;
  --h3-color: #f5d10f;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
  --slash-color: #f5d10f;
}
body.c2 .section--green-light {
  --section-background: #4b4b4d;
  --section-color: #fff;
  --h2-color: #fff;
  --h3-color: #f5d10f;
  --slash-color: #f5d10f;
}
body.c2 .section--five {
  --h3-color: #f5d10f;
}
body.c2 .section--footer {
  --section-background: #4b4b4d;
  --section-color: #fff;
  --link-color: #fff;
  --link-color-hover: #f5d10f;
}
body.c2 .section--navigation .navbar,
body.c2 .mobile-navigation {
  --link-color: #fff;
  --link-color-hover: #f5d10f;
  --slash-color: #f5d10f;
}
body.c2 .section--navigation .navbar .sub2,
body.c2 .mobile-navigation .sub2 {
  --link-color: #000;
  --link-color-hover: #c21f1e;
}
:root {
  --deskPadding: 73px;
}
@media (max-width: 1023px) {
  :root {
    --deskPadding: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskPadding: 25px;
  }
}
:root {
  --spacePart: 12px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 12px;
  }
}
:root {
  --spaceUnit: 22px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 22px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 22px;
  }
}
:root {
  --spaceTotal: 34px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 34px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 34px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #05612e;
  line-height: 1.25;
  cursor: pointer;
  color: #f5d10f;
  padding: 10px 30px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}
.button:hover,
.button:focus {
  color: #f5d10f;
  background-color: #044922;
}
.button:active {
  background-color: #01180b;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1280px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  text-transform: uppercase;
}
div.sub1 > .item {
  position: relative;
  margin: 0 10px;
}
div.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  padding: 13px 2px;
}
div.sub1 > .item > .menu:before {
  content: "/";
  padding-right: 3px;
  color: var(--slash-color);
}
div.sub1 > .item > .menu.path {
  color: var(--link-color-hover);
}
div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 200;
  background-color: #fff;
}
div.sub2 > .item {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  transition: max-height 250ms ease;
}
div.sub2 > .item > .menu {
  padding: 2px 15px;
  white-space: nowrap;
}
div.sub2 > .item > .menu.path {
  color: var(--link-color-hover);
}
div.sub2 > .item.init > .menu {
  padding-top: 15px;
}
div.sub2 > .item.exit > .menu {
  padding-bottom: 15px;
}
div.sub1 > .item:hover div.sub2 > .item {
  max-height: 150px;
  transition: max-height 700ms ease;
}
#head {
  width: 390px;
  padding: 50px 75px;
}
@media (min-width: 1280px) {
  #head {
    width: 430px;
  }
}
.cb-layout2 #head,
.cb-layout3 #head {
  width: 390px;
  padding: 50px 50px 50px var(--deskPadding);
}
@media (min-width: 1280px) {
  .cb-layout2 #head,
  .cb-layout3 #head {
    width: 430px;
  }
}
.section--footer .footcontent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.section--footer .footcontent .footpart {
  flex-basis: content;
}
h1 {
  font-size: 50px;
}
@media (min-width: 1280px) {
  font-size: 60px;
}
.cb-layout2 h1,
.cb-layout3 h1 {
  font-size: 42px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 20px;
}
.cb-layout1 .section--three .area .unit {
  width: 102.64550265%;
  margin-left: -1.32275132%;
  min-height: 40.9375vw;
  padding-right: 68.4303351%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.cb-layout1 .section--three .area .unit .part.pict:first-child {
  position: absolute;
  right: 0;
  top: 0;
  width: 64.08934708%;
  height: calc(100% - 24px);
  margin-left: 1.28865979%;
  margin-right: 1.28865979%;
}
.cb-layout1 .section--three .area .unit .part.pict:first-child * {
  height: 100%;
}
.cb-layout1 .section--three .area .unit .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout1 .section--three .area .unit.slim {
  padding-right: 0;
  padding-left: 68.4303351%;
}
.cb-layout1 .section--three .area .unit.slim .part.pict:first-child {
  right: auto;
  left: 0;
}
.cb-layout1 .section--three .area .unit .part {
  width: 92.26804124%;
  margin-left: 3.86597938%;
  margin-right: 3.86597938%;
}
.cb-layout1 .section--three .area .unit .part.tiny {
  width: 42.26804124%;
}
@media (min-width: 1586px) {
  .cb-layout1 .section--three .area .unit {
    min-height: 649px;
  }
}
.cb-layout1 .section--four .area .unit {
  width: 102.64550265%;
  margin-left: -1.32275132%;
  min-height: 47.65625vw;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 68.4303351%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.cb-layout1 .section--four .area .unit .part.pict:first-child {
  position: absolute;
  left: 34.62199313%;
  top: 0;
  width: 70.4467354%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout1 .section--four .area .unit .part.pict:first-child * {
  height: 100%;
}
.cb-layout1 .section--four .area .unit .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-layout1 .section--four .area .unit .part {
  width: 92.26804124%;
  margin-left: 3.86597938%;
  margin-right: 3.86597938%;
}
.cb-layout1 .section--four .area .unit .part.tiny {
  width: 42.26804124%;
}
@media (min-width: 1586px) {
  .cb-layout1 .section--four .area .unit {
    min-height: unset;
    height: 755px;
  }
  .cb-layout1 .section--four .area .unit .part.pict:first-child {
    width: 64.453125vw;
    max-height: 755px;
  }
}
/*# sourceMappingURL=./screen-large.css.map */